<template>
  <div class="app_con">
    <app-list
       ref="appList" 
      :rules="rules"
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :btnFlag="btnFlag"
    >
    <div class="m-t-10" slot="searchSlot">
        <search-bar :props="searchProps" @searchChange="searchChange"></search-bar>
      </div>
    </app-list>
    <el-dialog title="重置用户密码" :visible.sync="dialogVisible" width="800px" :close-on-click-modal="false">
      <div class="uesrCon">

        <el-input v-model="password" placeholder="请输入新密码" show-password>
          <template slot="prepend">新密码</template>
        </el-input>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    let _this = this
    return {
      curId:"",
      dialogVisible: false,
      password: '',
      searchProps: [
        { type: "input", label: "电话", prop: "phone" },
        { type: "input", label: "用户名", prop: "name" },
      ],
      rules: {
        //规则
        username: [
          //账号
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        password: [
          //密码
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        name: [
          //姓名
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        phone: [
          {
            //手机
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("手机号不能为空"));
              } else {
                if (!this.$test.mobile(value)) {
                  callback(new Error("手机号格式不正确"));
                } else {
                  callback();
                }
              }
            },
            required: true,
            trigger: "blur",
          },
        ],
        roleId: [
          //角色
          { required: true, message: "请选择角色", trigger: "blur" },
        ],
      },
      remote: api.common,

      apiName: "/user",
      title: "",
     searchQuery: { rand: 0, phone: '', name: '' },
     btnFlag: { addShow: true, delShow: false },
      props: [
        {
          label: "账号",
          prop: "username",
          align: "center",
        },

        {
          label: "姓名",
          prop: "name",
          align: "center",
        },
        {
          label: "手机",
          prop: "phone",
          align: "center",
        },
        {
          label: "角色",
          prop: "roleDescription",
          align: "center",
        },
        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 300,
          type: "operationLink",
          formatter: function (row) {
            let buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",
                handler: function () {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "remove",
                handler: function () {
        
                },
              },
              {
                text: "重置密码",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handler: function () {

                  _this.showChangePsw(row)
                },
              },
            ];

            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "账号",
          type: "input",
          prop: "username",
        },

        {
          label: "姓名",
          prop: "name",
          type: "input",
        },
        {
          label: "手机",
          prop: "phone",
          type: "input",
        },
        {
          label: "角色",
          prop: "roleIds",
          type: "select",
          multiple:true,
          selectData: [],
        },
      ],
    };
  },
  created() {
    this.getRoleList();
  },
  methods: {


    async showChangePsw(row) {
      this.curId = row.id
      this.password = ""
      this.dialogVisible = true
    },
    async handleSave() {

if (this.password.length < 6 || this.password.length > 12) {
  this.$message.error("请输入6-12位密码")
  return
}

let res = await api.common.commonPost({ apiName: '/user/resetPassWord', id: this.curId, password: this.password });
if (res.code == 200) {
  this.$message.success('重置密码成功！')
  this.dialogVisible = false
  this.curId = "",
    this.password = ""
}

},
    searchChange(e) {
      for (const key in e) {
        if (Object.hasOwnProperty.call(e, key)) {
          this.searchQuery[key] = e[key]
        }
      }
      this.searchQuery.rand++
      console.log(this.searchQuery)
    },
    async getRoleList() {
      await api.role.getList().then((res) => {
        let arr = res.data.data;
        console.log(arr);
        arr.forEach((item) => {
          this.formProps[3].selectData.push({
            value: item.id,
            label: item.description,
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  width: 400px;
}
</style>
